import React, { useState } from "react"
import { Link } from "gatsby"
import ResourcePartner from "../data/resource-partners"
import Layout from "../components/layout"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGlobe } from "@fortawesome/free-solid-svg-icons"
import SEO from "../components/seo"
import "./resource-partners.css"

const ResourcePartners = () => {
  const [resource, setResourcePartners] = useState(ResourcePartner.partners)
  return (
    <Layout>
      <SEO title="Resource Partners" />
      <div className="container primary-bg">
        <h1 className="small-padding"> Resource Partners </h1>
        <p className="small-padding">
          Supporting Miami-Dade's business community in time of crisis cannot be done alone. It is critical that we all take advantage of the strength of available business assets and resources.
        </p>
        <p className="small-padding">
          Does your business need help? The Miami-Dade Beacon Council's Small Business Xcelerator (SBX) is here to assist!
        </p>
        <p className="small-padding">
          The Miami-Dade Beacon Council, in partnership with Miami-Dade County, the Greater Miami Convention and Visitors Bureau (GMCVB) and other partner organizations, has created the Small Business Xcelerator (SBX): A Miami-Dade Beacon Council/OCOG Initiative to help our local business community. We are prepared to assist <b>your business</b> during this period of hardship. 
        </p>
        <p className="small-padding">
  To be matched to a business technical assistance partner, please complete the <a href="https://forms.office.com/Pages/ResponsePage.aspx?id=uNBkbADNjU2TTZl2xJTR8WOVBQ26UCxKq9fpi6HZpahUNTdRWUNITjg1SjMxRzRQVjhYRlRNM0xOVy4u" target="_blank">Small Business Needs Assessment Survey</a> and we will match you with the appropriate assistance partner shortly.
      </p>
              <a
              className="full-cta"
              href="https://forms.office.com/Pages/ResponsePage.aspx?id=uNBkbADNjU2TTZl2xJTR8WOVBQ26UCxKq9fpi6HZpahUNTdRWUNITjg1SjMxRzRQVjhYRlRNM0xOVy4u"
              target="_blank"
            >
              Click here to fill out the Small Business Needs Assessment Survey
            </a>
      </div>
      <hr />
      <section className="container">
        <label className="labelText">AXIS Help South Florida</label>
        {resource
          .filter(resource => resource.label == "axishelps")
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((resource, index) => {
            return (
              <div className="card">
                <label className="additonalFundingCardTitle">
                  {resource.name}
                </label>
                <div className="rowSpace">
                  <FontAwesomeIcon
                    className="iconStyle"
                    size="lg"
                    icon={faGlobe}
                  />
                  <a className="techLinks" href={resource.link} target="_blank">
                    {resource.link}
                  </a>
                </div>
              </div>
            )
          })}
        <hr />
        <label className="labelText">B2B COVID-19 Programs</label>
        {resource
          .filter(resource => resource.label == "b2b")
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((resource, index) => {
            return (
              <div className="card">
                <label className="additonalFundingCardTitle">
                  {resource.name}
                </label>
                <div className="rowSpace">
                  <FontAwesomeIcon
                    className="iconStyle"
                    size="lg"
                    icon={faGlobe}
                  />
                  <a className="techLinks" href={resource.link} target="_blank">
                    {resource.link}
                  </a>
                </div>
              </div>
            )
          })}
          <hr />
        <label className="labelText">Other Local Programs</label>
        {resource
          .filter(resource => resource.label == "other")
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((resource, index) => {
            return (
              <div className="card">
                <label className="additonalFundingCardTitle">
                  {resource.name}
                </label>
                <div className="rowSpace">
                  <FontAwesomeIcon
                    className="iconStyle"
                    size="lg"
                    icon={faGlobe}
                  />
                  <a className="techLinks" href={resource.link} target="_blank">
                    {resource.link}
                  </a>
                </div>
              </div>
            )
          })}
      </section>
    </Layout>
  )
}

export default ResourcePartners
