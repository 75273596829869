export const ResourcePartners = {
    partners: [
      {
        "name": "ActionCoach",
        "link": "https://www.actioncoach.com/",
        "label": "other"
      },
      {
        "name": "Akerman LLP",
        "link": "https://www.akerman.com/en/",
        "label": "other"
      },
      {
        "name": "Ascendus",
        "link": "https://www.ascendus.org/",
        "label": "other"
      },
      {
        "name": "Axis South Florida",
        "link": "https://www.axishelps.org/",
        "label": "other"
      },
      {
        "name": "Branches",
        "link": "https://branchesfl.org/contact-us/",
        "label": "other"
      },
      {
        "name": "CAMACOL (Latin Chamber of Commerce of the U.S.A.)",
        "link": "https://camacol.org/",
        "label": "other"
      },
      {
        "name": "Catalyst Miami",
        "link": "https://www.catalystmiami.org/",
        "label": "other"
      },
      {
        "name": "City of Coral Gables",
        "link": "https://www.coralgables.com/",
        "label": "other"
      },
      {
        "name": "City of Doral",
        "link": "https://www.cityofdoral.com/",
        "label": "other"
      },
      {
        "name": "City of Miami",
        "link": "https://www.miamigov.com/Home",
        "label": "other"
      },
      {
        "name": "Dade County Federal Credit Union",
        "link": "https://www.dcfcu.org/",
        "label": "other"
      },
      {
        "name": "DB + Partners LLC",
        "link": "https://dbpadv.com/",
        "label": "other"
      },
      {
        "name": "Florida International Bankers Association/ FIBA",
        "link": "https://www.fiba.net/default.aspx",
        "label": "other"
      },
      {
        "name": "Florida SBDC / FSBDC at FIU",
        "link": "https://business.fiu.edu/centers/sbdc/",
        "label": "other"
      },
      {
        "name": "Focal Point Business Coaching",
        "link": "https://www.focalpointcoaching.com/",
        "label": "other"
      },
      {
        "name": "Florida State Minority Supplier Development Council (FSMSDC)",
        "link": "https://fsmsdc.org/",
        "label": "other"
      },
      {
        "name": "Goldman Sachs 10K Small Business at Miami Dade College",
        "link": "https://www.mdc.edu/10ksb/",
        "label": "other"
      },
      {
        "name": "Greater Miami Convention & Visitors Bureau  (GMCVB)",
        "link": "http://www.greatermiami.com",
        "label": "other"
      },
      {
        "name": "Hialeah Chamber of Commerce",
        "link": "http://www.greatermiami.com",
        "label": "other"
      },
      {
        "name": "Marcum LLP",
        "link": "https://www.marcumllp.com/offices/miami-florida",
        "label": "other"
      },
      {
        "name": "Miami Bayside Foundation",
        "link": "https://miamibaysidefoundation.org/",
        "label": "other"
      },
      {
        "name": "Miami-Dade Chamber of Commerce",
        "link": "http://www.m-dcc.org",
        "label": "other"
      },
      {
        "name": "Miami-Dade County",
        "link": "http://www.miamidade.gov",
        "label": "other"
      },
      {
        "name": "Miami Downtown Development Authority (DDA)",
        "link": "http://www.miamidda.com",
        "label": "other"
      },
      {
        "name": "Miami Eats",
        "link": "https://www.miamiandbeaches.com/miamieats?utm_source=Vanity_URL&utm_medium=www.gmcvb.com/MiamiEats",
        "label": "other"
      },
      {
        "name": "Oren Gulasa CPA Services",
        "link": "http://orengulasa.com/",
        "label": "other"
      },
      {
        "name": "Overtown Connect - Venture Café Miami",
        "link": "https://venturecafemiami.org/overtown/",
        "label": "other"
      },
      {
        "name": "Prospera USA",
        "link": "https://prosperausa.org/",
        "label": "other"
      },
      {
        "name": "Rainmakers Elite",
        "link": "https://rainmakerselite.com/",
        "label": "other"
      },
      {
        "name": "SCORE Miami-Dade",
        "link": "https://miamidade.score.org/",
        "label": "other"
      },
      {
        "name": "SEEED",
        "link": "https://seeed.us/pages/square-sos-covid-19",
        "label": "other"
      },
      {
        "name": "SOBEWFF & FIU Hospitality",
        "link": "https://sobewff.org/about/fiu/",
        "label": "other"
      },
      {
        "name": "South Florida Regional Planning Council",
        "link": "https://sfregionalcouncil.org/",
        "label": "other"
      },
      {
        "name": "The Burdham Group",
        "link": "http://burdhamgroup.com/index.html",
        "label": "other"
      },
      {
        "name": "Thynk Global",
        "link": "https://www.thynkglobal.com/",
        "label": "other"
      },
        {
        "name": "Tools for Change",
        "link": "https://tfcmiami.org/",
        "label": "other"
      },
      {
        "name": "Town of Medley",
        "link": "http://www.townofmedley.com/",
        "label": "other"
      },
      {
        "name": "Trembly Law Firm",
        "link": "https://tremblylaw.com/",
        "label": "other"
      },
      {
        "name": "Oolite Artis Relief Fund",
        "link": "https://oolitearts.org/relieffund/",
        "label": "other"
      },
        {
        "name": "Overtown Children & Youth Coalition",
        "link": "https://overtowncyc.org/",
        "label": "other"
      },
        {
        "name": "Partners for Self-Employment",
        "link": "http://partnersforselfemployment.org/",
        "label": "other"
      },
        {
        "name": "YWCA Greater Miami-Dade",
        "link": "https://www.ywcasouthflorida.org/",
        "label": "other"
      },
      {
        "name": "B2B Healthcare Supply Network Building",
        "link": "https://www.beaconcouncil.com/wp-content/uploads/2020/04/SupplyChainB2bResources_Final1-1.pdf",
        "label": "b2b"
      },
     
    ],
  }
  
  export default ResourcePartners
  
